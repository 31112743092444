import * as React from "react";
import Layout from "../../Layout/MasterLayout/MasterLayout";
import Button from "../../components/Button/Button";
import CardValores from "../../components/CardValores/CardValores";

import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

import imgMenina from "../../assets/images/etapas-de-aprendizagem/ensino-fundamental/ensino-fundamental.png";
import SEO from "../../components/SEO/SEO";

import '../../components/css/images.css'

export default function EnsinoFundamental() {
	return (
		<Layout>
			<SEO title="Ensino fundamental" lang="pt-br"/>
			<MDBContainer>
				<MDBRow>
					<MDBCol col={12} md={6} className="order-1 order-sm-0 my-auto">
						<h1 className="pageTitle fgterciary-gradient">
							ensino fundamental
						</h1>
						<h3 className="fgterciary-gradient">nosso propósito</h3>
						<p className="text-justify">
							Na Escola de Aplicação Feevale, a construção do conhecimento
							acontece através da interação e da problematização a partir de
							fenômenos e da busca permanente da autogestão. Nessa etapa de
							aprendizagem, o estudante desenvolve múltiplas linguagens, o
							pensamento criativo, lógico e crítico, ampliando a compreensão de
							si mesmo, do mundo natural e social, das relações dos seres
							humanos entre si e com a natureza.
						</p>
						<h3 className="fgterciary-gradient text-left">horários</h3>
						<ul style={{ listStyleType: "none" }}>
							<li>
								1º e 2º Ciclo (1º, 2º, 3º, 4º e 5º ano do Ensino Fundamental)
							</li>
							<ul style={{ listStyleType: "none" }}>
								<li>// manhã 7h20min às 11h50min</li>
								<li>// tarde 13h20min às 17h50min</li>
							</ul>
							<li>3º e 4º Ciclo (6º, 7º, 8º e 9º ano do Ensino Fundamental)</li>
							<ul style={{ listStyleType: "none" }}>
								<li>// manhã 7h20min às 11h50min</li>
							</ul>
						</ul>
					</MDBCol>
					<MDBCol
						col={12}
						md={6}
						className="py-5 text-justify order-0 order-sm-1 imagemTopo"
					>
						<img src={imgMenina} className="img-fluid" />
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<h3 className="fgterciary-gradient text-left">
							conheça as atividades de contraturno
						</h3>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol col={12} md={6} className="pb-2">
						<Button
							type="ensino"
							to="/contraturno/atividades-extracurriculares"
							text='Atividades extracurriculares <span>Até 2º ciclo</span>'
						/>
					</MDBCol>
					<MDBCol col={12} md={6}>
						<Button
							type="ensino"
							to="/contraturno/oficinas"
							text='Oficinas <span>Após o 3º ciclo</span>'
						/>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<h3 className="fgterciary-gradient pt-3 pb-2">valores*</h3>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<CardValores
						bgColor="bgterciary-gradient"
						amount="12"
						value="1.479,84"
					/>
					<CardValores
						bgColor="bgterciary-gradient"
						amount="11"
						value="1.614,37"
					/>
					<CardValores
						bgColor="bgterciary-gradient"
						amount="10"
						value="1.775,81"
					/>
				</MDBRow>
			</MDBContainer>
		</Layout>
	);
}
